/* Reset default margin and padding */
body, h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero section */
.hero {
  background-color: #3498db;
  color: #fff;
  padding: 100px 0;
  text-align: center;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  margin-bottom: 30px;
}

.hero .explore-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #3498db;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.hero .explore-btn:hover {
  background-color: #2ecc71;
  color: #fff;
}

/* About section */
.about-us {
  background-color: #ecf0f1;;
  padding: 50px 0;
  line-height: 35px;
  padding: 50px 50px;  
  text-align: center;
}

.about-us h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 18px;
}

/* Vision-Mission section */
.vision-mission {
  /* background-color: #ecf0f1; */
  padding: 50px 0;
}

.vision-mission h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.vision-mission p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Core Values section */
.core-values {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
}

.core-values h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.core-values ul {
  list-style-type: none;
  margin-bottom: 20px;
}

.core-values li {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Services section */
.services {
  background-color: #ecf0f1;
  padding: 50px 0;
}

.services h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.services ul {
  list-style-type: none;
  margin-bottom: 20px;
}

.services li {
  font-size: 18px;
  margin-bottom: 10px;
}

.services p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Manpower Requirement section */
.manpower-requirement {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
}

.manpower-requirement h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.manpower-requirement p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Job Details section */
/* .job-details {
  background-color: #ecf0f1;
  padding: 50px 0;
  text-align: center;
}

.job-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.job-details p {
  font-size: 18px;
  margin-bottom: 20px;
} */

/* Footer */
footer {
  background-color: #000000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

footer p {
  font-size: 16px;
}


/* Vision and Mission section */
.vision-mission {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* background-color: #ffffff; */
  padding: 50px 0;
}

.vision-content,
.mission-content {
  flex: 1;
  padding: 0 20px;
}

.vision-content h2,
.mission-content h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.vision-content p,
.mission-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}



/* CoreValues.css */
.core-values {
  padding: 50px 0;
  text-align: center;
}

.core-values h2 {
  font-size: 28px;
  margin-bottom: 30px;
}

.core-values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.core-value-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  width: 300px;
}

.core-value-card .icon {
  color: #007bff;
  font-size: 48px;
  margin-bottom: 20px;
}

.core-value-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.core-value-card p {
  font-size: 18px;
}



/* Services.css */
.services {
  padding: 100px 0;
  text-align: center;
}

.services h2 {
  font-size: 28px;
  margin-bottom: 30px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: center;
  gap: 20px;
}

.service-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px 30px;
  text-align: center;
  width: 200px;
}

.service-item .icon {
  color: #007bff;
  font-size: 55px;
  /* margin-bottom: 10px; */
}

.service-item p {
  font-size: 18px;
}

.terms-conditions {
  font-size: 16px;
  margin-top: 30px;
  line-height: 1.5;
}


/* JobDetails.css */
.job-details {
  background-color: #fff;
  padding: 100px 40px;
  text-align: center;
}

.job-details h2 {
  font-size: 28px;
  margin-bottom: 30px;
}

.job-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.job-item {
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 300px;
}

.job-item .icon {
  color: black;
  font-size: 45px;
  margin-bottom: 10px;
}

.job-item p {
  font-size: 18px;
}

/* section{
  height: 90vh;
} */


.hero {
  position: relative;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; /* Set font color to white */
  text-align: center;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  z-index: 1;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.explore-btn {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #007bff; /* Set button background color */
  color: #fff; /* Set button text color */
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.explore-btn:hover {
  background-color: #0056b3; /* Change button background color on hover */
}


.vision-mission {
  display: flex;
  /* justify-content: space-around; */
  align-items: flex-start;
  padding: 4rem 0;
}

.vision-content,
.mission-content {
  flex: 1;
  text-align: center;
}

.icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.vision-content h2,
.mission-content h2 {
  /* font-size: 2.5rem; */
  margin-bottom: 1rem;
}

.vision-content p,
.mission-content p {
  /* font-size: 1.5rem; */
  line-height: 1.6;
}


/* Skilled Manpower Requirement section */
.skilled-manpower {
  padding: 50px 0;
  text-align: center;
}

.skilled-manpower h3 {
  font-size: 28px;
  margin-bottom: 30px;
}

.requirements {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.requirement {
  background-color: #fff;
  border-radius: 50%; /* Make the card circular */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 35px 10px;
  text-align: center;
  width: 200px;
}

.requirement .icon {
  color: #007bff;
  font-size: 40px;
  margin-bottom: 0px;
}

.requirement p {
  font-size: 18px;
}


.job-registration {
  background-color: #f4f4f4;
  padding: 50px 0;
  text-align: center;
}

.job-registration h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.job-registration p {
  font-size: 18px;
  margin-bottom: 30px;
}

.job-registration-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.job-registration-btn:hover {
  background-color: #0056b3;
}


/* Adjustments for mobile view */
@media (max-width: 768px) {
  .skilled-manpower {
    padding: 30px 0;
  }

  .skilled-manpower h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .requirements {
    flex-wrap: wrap;
  }

  .requirement {
    width: 45%; /* Adjust width for smaller screens */
    margin-bottom: 20px; /* Add some space between items */
  }

  .requirement .icon {
    font-size: 32px; /* Decrease icon size */
  }

  .requirement p {
    font-size: 16px; /* Decrease text size */
  }
}


/* About Us section */


.about-us h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 18px;
  line-height: 1.6;
}

/* Vision and Mission section */
.vision-mission {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0;
}

.vision-content,
.mission-content {
  width: 100%;
  padding: 0 20px;
}

.vision-content h2,
.mission-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.vision-content p,
.mission-content p {
  font-size: 16px;
  line-height: 1.6;
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  .about-us {
    background-color: #ecf0f1;
    padding: 20px 10px;
    /* text-align: center; */
  }

  .about-us p {
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
    padding: 0 20px;
  }

  .vision-content,
  .mission-content {
    padding: 0px; /* Remove padding for smaller screens */
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .vision-content h2,
  .mission-content h2 {
    font-size: 20px; /* Decrease heading font size for mobile */
   
  }

  .vision-content p,
  .mission-content p {
    font-size: 14px; /* Decrease paragraph font size for mobile */
    text-align: justify;
  }
}

/* Brochures section */
.brochures {
  margin-top: 30px; /* Add some space between the registration button and the brochures */
  display: flex;
  justify-content:center; /* Align brochures horizontally */
  flex-wrap: wrap; /* Allow wrapping to multiple rows if needed */
}

.brochures h3 {
  width: 100%; /* Ensure title takes full width */
  text-align: center; /* Center align the title */
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Set text color for the title */
}

.brochures ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Align brochures horizontally within the container */
  flex-wrap: wrap; /* Allow wrapping to multiple rows if needed */
}

.brochures li {
  margin-bottom: 10px;
}

.brochures a {
  display: inline-block;
  padding: 10px 20px;
  
  background-color: #007bff; /* Set button background color */
  color: #fff; /* Set button text color */
  font-size: 18px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-left: 60px; /* Add margin between brochures */
  margin-bottom: 10px; /* Add margin below each brochure */
}

.brochures a:last-child {
  margin-right: 0; /* Remove margin from the last brochure to prevent extra space */
}

.brochures a:hover {
  background-color: #0056b3; /* Change button background color on hover */
}
