nav {
  background-color: #000000;
  color: #fff;
  padding: 1.5rem 10rem; /* Top/Bottom: 1rem, Left/Right: 2rem */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0; /* Stick the navbar to the top of the viewport */
  z-index: 1000; /* Ensure the navbar stays on top of other content */
}

.logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu-toggle {
  display: none; /* Hide hamburger button by default */
  cursor: pointer;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: transform 0.3s, opacity 0.3s;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-right: 2rem;
}

.nav-links li:last-child {
  margin-right: 0; /* Remove margin from the last item */
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* Show hamburger button for mobile */
  }

  .nav-links {
    display: none; /* Hide nav links for mobile */
    position: absolute;
    width: 90%;
    top: 60px;
    left: 0;
    background-color: #333;
    padding: 1rem 2rem; /* Top/Bottom: 1rem, Left/Right: 2rem */
    margin: 0;
    flex-direction: column;
  }

  .nav-links.open {
    display: flex; /* Show dropdown for mobile when menu is open */
  }

  .nav-links li {
    margin-right: 0; /* Remove margin for mobile view */
    margin-bottom: 1rem; /* Add margin-bottom for mobile view */
  }
}
